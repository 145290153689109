import { createContext, useState } from 'react';
import WalletConnectProvider from '@walletconnect/web3-provider';
import QRCodeModal from '@walletconnect/qrcode-modal';

import BYOPillArtifact from '../../contracts/BYOPillLive.json';
import BYOVapeArtifact from '../../contracts/BYOVape.json';

import Web3 from 'web3';

const initialState = {
  account: null,
  web3: null,
};

const Web3Context = createContext();

// Wallet Connect
const provider = new WalletConnectProvider({
  infuraId: '6f2ad42924274e0cbfe3dfa84e56ff6a',
  qrcode: false,
  pollingInterval: 10000,
});

const Web3ContextProvider = ({ children }) => {
  const [account, setAccount] = useState(initialState.account);
  const [contract, setContract] = useState(null);
  const [keyContract, setKeyContract] = useState(null);
  const [web3, setWeb3] = useState(initialState.web3);
  const [initialized, setInitialized] = useState(false);

  const initializeWeb3 = async () => {
    var web3 = null;
    if (window.ethereum) {
      // Metamask
      web3 = new Web3(window.ethereum);
      setWeb3(web3);
    } else {
      // No metamask, fallback to wallet connect
      try {
        await provider.enable();
        QRCodeModal.close();
        provider.on('disconnect', (accounts) => {
          setAccount(null);
        });
        web3 = new Web3(provider);
        setWeb3(web3);
      } catch (e) {
        console.log(e);
      }
    }
    // Get accounts
    await getAccounts(web3);

    // Get contract
    var nftContract = null;
    var keyCntrct = null;

    try {
      nftContract = new web3.eth.Contract(
        BYOPillArtifact.output.abi,
        '0xbd275ce24f32d6ce4e9d9519c55abe9bc0ed7fcf',
      );

      keyCntrct = new web3.eth.Contract(
        BYOVapeArtifact.abi,
        '0x06Fd60173bF869B8CE4439E3477223C013baD466',
      );
    } catch (e) {
      alert(
        'Failed to setup Ethereum connection, please check your wallet network.',
      );
      console.log(e);
    }

    setContract(nftContract);
    setKeyContract(keyCntrct);

    setInitialized(true);
  };

  const getAccounts = async (web3) => {
    const accounts = await web3.eth.getAccounts();
    if (accounts.length > 0) {
      setAccount(accounts[0]);
    }
  };

  const connectMetamask = async () => {
    if (window.ethereum) {
      // Metamask direct
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then((accounts) => {
          if (accounts.length === 0) {
            console.log('Please connect to MetaMask.');
          } else if (accounts[0] !== account) {
            setAccount(accounts[0]);
          }
        });
    } else {
      // Wallet connect
      try {
        QRCodeModal.open(provider.connector.uri, () => {
          QRCodeModal.close();
        });
      } catch {}
    }
  };

  return (
    <Web3Context.Provider
      value={{
        account,
        setAccount,
        web3,
        setWeb3,
        connectMetamask,
        initializeWeb3,
        contract,
        keyContract,
        initialized,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

export { Web3Context, Web3ContextProvider };
