import React, { useContext, useState, useRef, useEffect } from 'react';
import NumericInput from 'react-numeric-input';
import { SkyLightStateless } from 'react-skylight';
import Loader from 'react-loader-spinner';
import { Web3Context } from '../../data/Web3Context/Web3Context';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './VapePurchaseCard.css';

function VapePurchaseCard({ nft, color, border }) {
  const { web3, account, connectMetamask, contract, keyContract } =
    useContext(Web3Context);
  const [transactionHash, setTransactionHash] = useState(null);
  const [mintQuantity, setMintQuantity] = useState(1);
  const [transactionConfirmed, setTransactionConfirmed] = useState(false);
  const [currentPillBalance, setCurrentPillBalance] = useState(-1);
  const [isLoading, setLoading] = useState(false);
  const [mintedVapes, setmintedVapes] = useState(-1);
  const [eligibleCount, setEligibleCount] = useState(-1);
  const [claimingOpen, setClaimingOpen] = useState (true);
  const [modalOpen, setModalOpen] = useState(false);
  const [canMint, setCanMint] = useState(true);
  const numericInput = useRef(null);

  useEffect(() => {

    if (!contract || !keyContract) {
      return;
    }

    getPillBalance();
    getmintedVapes();
    getSnapshotCount();
    getClosed();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, contract, keyContract]);

  const getSnapshotCount = () => {
    fetch(`https://api.byopills.com/byovape/snapshot/${account}`)
      .then((data) => data.json())
      .then((parsed) => {
        setEligibleCount(parsed.count);
      });
  };

  const getClosed = async () => {
    try {
      const { isCollectingOpen } = keyContract.methods;
      const isOpen = await isCollectingOpen(0).call();
      setClaimingOpen(isOpen);
    } catch (e) {
      console.log(e);
    }
  }

  const getPillBalance = async () => {
    try {
      const pillBalance = await contract.methods.balanceOf(account).call();
      setCurrentPillBalance(pillBalance);
    } catch (e) {}
  };

  const getmintedVapes = async () => {
    try {
      const { claimed } = keyContract.methods;
      const mintedVapes = await claimed(0, account).call();
      setmintedVapes(mintedVapes);
    } catch (e) {
      console.log(e);
    }
  };

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const mint = async () => {
    if (!canMint) {
      return;
    }

    setLoading(true);
    setCanMint(false);

    const fetchRes = await fetch(
      `https://api.byopills.com/byovape/merkle/proof/${account}`,
    );
    const data = await fetchRes.json();

    if (!data) {
      alert('An unknown error has occured. Please try again in a few.');
      setLoading(false);
      setCanMint(true);
      return;
    }

    const { idx, maxCount, proof } = data;

    if (proof === 'Not Found' || isNaN(maxCount)) {
      alert('This address is not on the whitelist.');
      setLoading(false);
      setCanMint(true);
      return;
    }

    // Reset
    setTransactionHash(null);
    setTransactionConfirmed(false);

    if (mintQuantity <= 0) {
      alert('Please input a valid number !');
      setLoading(false);
      setCanMint(true);
      return;
    }

    const { whitelistCollect } = keyContract.methods;

    numericInput.current.refsInput.defaultValue = mintQuantity;

    whitelistCollect(mintQuantity, 0, idx, maxCount, proof).estimateGas(
      {
        from: account,
      },
      async (err, gas) => {
        if (err) {
          if (err.message.indexOf('Sale must') > -1) {
            alert('Sale is not active yet !');
          } else if (err.message.indexOf('Tx max') > -1) {
            alert('75 max per transaction !');
          }
           else if (err.message.indexOf('Collecting not') > -1) {
            alert(
              'Vape collection is not open yet ! Please wait until it is open.',
            );
          } else if (err.message.indexOf('Invalid merkle') > -1) {
            alert(
              'An error has occured, please make sure your address is whitelisted.',
            );
          } else if (err.message.indexOf('Minting more') > -1) {
            alert(
              'You are not allowed to mint this number of vapes at the moment. You have probably minted your eligible vapes already.',
            );
          } else if (err.message.indexOf('Linked asset') > -1) {
            alert(
              'You do not have enough pills to match your vape eligibility for this transaction. Please either mint less vapes based on the number of pills you own, or purchase pills from the secondary market to proceed.',
            );
          }
          setLoading(false);
          setCanMint(true);
          return;
        }
        numericInput.current.refsInput.defaultValue = mintQuantity;
        whitelistCollect(mintQuantity, 0, idx, maxCount, proof).send(
          {
            from: account,
            gas: gas,
          },
          async function (error, txHash) {
            if (error) {
              setLoading(false);
              setCanMint(true);
              return;
            }

            setTransactionHash(txHash);
            setModalOpen(true);
            setLoading(false);
            setCanMint(true);

            let transactionReceipt = null;
            while (transactionReceipt == null) {
              transactionReceipt = await web3.eth.getTransactionReceipt(txHash);
              await sleep(1000);
            }

            setTransactionConfirmed(true);
            setTransactionHash(transactionReceipt.transactionHash);
          },
        );
      },
    );
  };

  return (
    <div className="VapePurchaseCard">
      <SkyLightStateless
        isVisible={modalOpen}
        onCloseClicked={() => {
          setModalOpen(false);
        }}
      >
        <div className="Modal_Body">
          {!transactionConfirmed ? (
            <div className="Modal_Title">
              <Loader type="Puff" color="white" height={50} width={50} />
              <p className="Modal_Status">
                Transaction Processing ... <br />
                <span>
                  You can either wait or close this and check your wallet or
                  etherscan for confirmation
                </span>
              </p>
            </div>
          ) : (
            <div className="Modal_Title">
              <p>Transaction Confirmed !</p>
            </div>
          )}

          <p>SUMMARY</p>
          <div className="separator"></div>
          <div className="Modal_Entries">
            <div className="Modal_Entry">
              <p>TRANSACTION HASH</p>
              <p>
                {transactionHash}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://etherscan.io/tx/${transactionHash}`}
                >
                  MORE INFO...
                </a>
              </p>
            </div>
            <div className="Modal_Entry">
              <p>FROM</p>
              <p>{account}</p>
            </div>
          </div>
          <div className="separator"></div>

          <div className="Modal_Actions">
            <button
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Mint more
            </button>
          </div>
          {transactionConfirmed && (
            <div className="Modal_Confirmation">
              Your transaction has now been confirmed ! Your BYOVape should now
              be minted , and visible on{' '}
              <a href="https://opensea.io/collection/byovape">Opensea.io 🙌 </a>
            </div>
          )}
        </div>
      </SkyLightStateless>

      <div>
        <p className="Info_Title" style={{ color: "black" }}>
          Mint <span>BYOVape</span> for <span>FREE + GAS</span>
        </p>
        <p className="Info_Title" style={{ color: "black" }}>
          NOTE: 75 PER TRANSACTION MAX
        </p>
      </div>

      {account && (
        <NumericInput
          ref={numericInput}
          className="form-control"
          value={mintQuantity}
          onChange={setMintQuantity}
          min={1} 
          max={eligibleCount}
          step={1}
          precision={0}
          size={5}
          mobile
        />
      )}

      {account ? (
        !isLoading ? (

          claimingOpen ?
          <button
            onClick={() => mint()}
            className="VapePurchaseCard_ActionButton"
          >
            MINT VAPE
          </button>
          :
          <button
            className="VapePurchaseCard_ActionButton"
          >
            CLAIMING CLOSED
          </button>
        ) : (
          <button className="VapePurchaseCard_ActionButton">LOADING ...</button>
        )
      ) : (
        <button
          onClick={() => connectMetamask()}
          className="VapePurchaseCard_ActionButton"
        >
          CONNECT WALLET
        </button>
      )}

      {account && (
        <div className="VapePurchaseCard_Footer">
          {eligibleCount !== -1 && (
            <p style={{ color: "#9d00ff" }}>
              Eligible For: <span>{eligibleCount} Vape(s)</span>
            </p>
          )}
          {currentPillBalance !== -1 && (
            <p style={{ color: "#9d00ff", flex: '0.5' }}>
              Pill Balance: <span>{currentPillBalance} Pill(s)</span>
            </p>
          )}
          {mintedVapes !== -1 && (
            <p style={{ color: "#9d00ff", flex: '0.5' }}>
              Vapes Claimed: <span>{mintedVapes} Vape(s)</span>
            </p>
          )}
          {/* {mintedVapes !== -1 && (
            <p
              className="Info_KeysLeft"
              style={{
                color: "#9d00ff",
                width: '100%',
              }}
            >
              You can still mint up to{' '}
              {eligibleCount >= currentPillBalance ? (
                <span>{eligibleCount - mintedVapes} vapes</span>
              ) : (
                <span>{currentPillBalance - mintedVapes} vapes</span>
              )}
            </p>
          )} */}
        </div>
      )}
    </div>
  );
}

export default VapePurchaseCard;
