import React from 'react';
import { withRouter } from 'react-router-dom';

import Vape from '../../assets/vape.png';
import VapePurchaseCard from '../../components/VapePurchaseCard/VapePurchaseCard';

import './Claim.css';

function VapeClaim() {

  return (
    <div className="VapeClaim">
     
      <div className="VapeClaim_MainSection">
      <img alt="byoplogo" className="Snapshot_Logo" src={Vape} />
        <VapePurchaseCard color="yellow" border="yellow" />
      </div>

      <div className="VapeClaim_Header">
        <a style={{display: 'flex'}} href="https://byopills.com" target="_blank" rel="noopener noreferrer" aria-current="page"><img src="https://uploads-ssl.webflow.com/619b48f76e0527c64a2c3dc1/619b55181893d62a38c24b6b_Pill%20Icon.svg" loading="lazy" width="50" alt="" /><img className="byoimg" src="https://uploads-ssl.webflow.com/619b48f76e0527c64a2c3dc1/61a0c8675f6b9e55d18c9053_One%20word.svg" loading="lazy" width="120" alt=""></img></a>
        
      </div>

      <a href="https://etherscan.io/address/0x06fd60173bf869b8ce4439e3477223c013bad466#code" target="_blank" rel="noopener noreferrer" className="VapeClaim_Footer">BYOVape Smart Contract</a>
    </div>
  );
}

export default withRouter(VapeClaim);
