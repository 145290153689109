import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// Components

import './App.css';
import Snapshot from '../pages/Snapshot/Snapshot';
import Claim from '../pages/Claim/Claim';
import { Web3Context } from '../data/Web3Context/Web3Context';

function App() {

  const { initializeWeb3 } = useContext(Web3Context);

  useEffect(() => {
    initializeWeb3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Claim />
        </Route>
        <Route exact path="/snapshot">
          <Snapshot />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
